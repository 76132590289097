<template>
  <div class="dropdown-search-container">
    <div class="icon-wrapper">
      <uds-icon icon-name="search" :color="udsColors.udsNeutral800" size="small" />
    </div>
    <div class="input-wrapper">
      <input
        ref="searchInput"
        :value="data"
        class="dp-search-input"
        type="text"
        :placeholder="placeholder"
        @input="updateData"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    data: string | null;
    placeholder?: string;
  }>(),
  {
    placeholder: "Rechercher...",
  },
);

const udsColors = getUdsColors();

const emit = defineEmits<{
  (e: "update:data", newValue: string | null): void;
}>();

const searchInput = ref<HTMLInputElement | null>();
const { focused } = useFocus(searchInput);

function updateData(event: Event) {
  const newValue = (event.target as HTMLInputElement)?.value ?? null;
  emit("update:data", newValue);
}

onMounted(() => {
  // Dirty hack to focus the input after the dropdown is opened
  // This is needed because the dropdown is not a real input
  setTimeout(() => {
    focused.value = true;
  }, 300);
});
</script>

<style lang="scss" scoped>
.dropdown-search-container {
  display: flex;
  align-items: center;
  width: 10px;
  flex-grow: 1;

  .icon-wrapper {
    margin-left: 21px;
    margin-right: 21px;
  }

  .input-wrapper {
    margin-right: 21px;

    .dp-search-input {
      outline: none;
      border: none;
      font-size: 16px;
      color: $uds-neutral-800;
      font-weight: $uds-weight-450;
    }
  }
}
</style>
